import {
  EditorReadyFn,
  FlowEditorSDK,
  FlowPlatformOptions,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import {
  BM_PLAYGROUND,
  BM_SETTINGS,
  HELP_ID,
  WIDGET_ID,
  WIX_CHAT_APP_DEF_ID,
} from './constants';
import {
  getComponentRef,
  getPresetId,
  getWidgetPosition,
  updateLayout,
} from './utils';

const OPPOSITE_POSITION = {
  left: 'right',
  right: 'left',
} as const;

const AUTO_OPEN_PRESET_IDS = [
  'aISiteChatStarIcon',
  'd30617e1-4ece-4c1b-aab1-003f6e217751_c1dmp',
];

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  platformOptions,
  flowAPI,
) => {
  if (!platformOptions.firstInstall) {
    // Reset flags being set on first install
    const componentRef = await getComponentRef(editorSDK, appDefinitionId);
    editorSDK.document.tpa.data.remove('', {
      compRef: componentRef!,
      key: 'initialVisualLayoutState',
    });
  }

  if (platformOptions.firstInstall) {
    /**
     * `presetId` is name of preset used by Add Panel in Editor to install Widget.
     * this ensures Widget is installed in open state in case of certain presets.
     * https://github.com/wix-private/wix-ai-assistant-bot/issues/2509
     */
    const { componentRef, presetId } = await getPresetId(
      editorSDK,
      appDefinitionId,
    );

    if (presetId && AUTO_OPEN_PRESET_IDS.includes(presetId)) {
      editorSDK.document.tpa.data.set('', {
        compRef: componentRef!,
        key: 'initialVisualLayoutState',
        value: 'visible', // not using enum to avoid bundle size increase
      });
    }

    /**
     * Calculate the position of the Wix Chat widget and the AI Site Chat widget
     * If the widgets are in the same position, move the AI Site Chat widget to the opposite position
     */
    const isWixChatInstalled =
      await editorSDK.document.application.isApplicationInstalled('', {
        appDefinitionId: WIX_CHAT_APP_DEF_ID,
      });

    if (!isWixChatInstalled) {
      return;
    }

    const [wixChatPosition, aiSiteChatPosition] = await Promise.all([
      getWidgetPosition({
        editorSDK,
        appDefId: WIX_CHAT_APP_DEF_ID,
      }),
      getWidgetPosition({
        editorSDK,
      }),
    ]);

    if (
      wixChatPosition &&
      aiSiteChatPosition &&
      wixChatPosition === aiSiteChatPosition
    ) {
      await updateLayout({
        editorSDK,
        position: OPPOSITE_POSITION[wixChatPosition],
      });
    }
  }
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  contextParams,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    managementActionsBuilder.learnMoreAction().set({
      id: HELP_ID,
    });

    managementActionsBuilder.mainActions().addAction(
      {
        title: t('app.manifest.manage'),
        icon: 'aiIcon',
        onClick: (event) => {
          editorSDK.editor.openDashboardPanel('', {
            url: BM_SETTINGS,
            closeOtherPanels: true,
          });
        },
      },
      {
        title: t('app.manifest.test'),
        icon: 'menubarSiteSettingsPromotion',
        onClick: (event) => {
          editorSDK.editor.openDashboardPanel('', {
            url: BM_PLAYGROUND,
            closeOtherPanels: true,
          });
        },
      },
    );
  });

  appManifestBuilder.configureWidget(WIDGET_ID, (widgetBuilder) => {
    widgetBuilder.gfpp().set('mainAction2', {
      label: t('app.settings.gfpp.test'),
      onClick: ({ detail }) => {
        editorSDK.editor.openDashboardPanel('', {
          url: BM_PLAYGROUND,
          closeOtherPanels: true,
        });
      },
    });

    widgetBuilder.set({
      displayName: t('app.manifest.name'),
    });

    widgetBuilder.behavior().set({
      canReparent: false,
      duplicatable: false,
      pinnable: false,
      closable: false,
      removable: false,
      resizable: false,
      toggleShowOnAllPagesEnabled: false,
      rotatable: false,
    });
  });

  return appManifestBuilder.build();
};

export const exports = async (
  editorSDK: FlowEditorSDK,
  { initialAppData }: FlowPlatformOptions,
) => {
  return {
    private: {
      setPosition: async (position: 'left' | 'right') => {
        const componentRef = await getComponentRef(
          editorSDK,
          initialAppData.appDefinitionId,
        );

        if (!componentRef) {
          return;
        }

        await editorSDK.components.properties.update('token', {
          componentRef,
          props: {
            placement: `BOTTOM_${position.toUpperCase()}`,
          },
        });
      },

      getPosition: async () => {
        const componentRef = await getComponentRef(
          editorSDK,
          initialAppData.appDefinitionId,
        );

        if (!componentRef) {
          return;
        }

        const properties = await editorSDK.components.properties.get('token', {
          componentRef,
        });

        return properties?.placement;
      },
    },
  };
};
