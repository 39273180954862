import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const getComponentRef = async (
  editorSDK: FlowEditorSDK,
  appId: string,
) => {
  const comp = (
    await editorSDK.document.tpa.app.getAllComps('token', appId)
  ).at(0);

  const componentRef = comp
    ? await editorSDK.document.components.getById('token', {
        id: comp.id,
      })
    : undefined;

  return componentRef;
};
